// src/utils/firebase.utils.js

import { auth, db } from '../firebaseConfig';
import { collection, getDoc, doc, getDocs, getFirestore, setDoc, addDoc, serverTimestamp, updateDoc, onSnapshot, query, where } from 'firebase/firestore';
const firestore = getFirestore();

/**
 * @typedef {Object} SessionData
 * @property {string} [id]
 * @property {string} [coachId]
 * @property {string} [status]
 * @property {Date} [createdAt]
 * @property {string} [apiKey]
 */

export const updateSessionStatus = async (sessionId, status) => {
  try {
    const sessionRef = doc(db, 'sessions', sessionId);
    await updateDoc(sessionRef, {
      status,
      finishedAt: status === 'finished' ? serverTimestamp() : null
    });
    return true;
  } catch (error) {
    console.error('Error updating session status:', error);
    return false;
  }
};

export const getSessionWithMessages = async (sessionId) => {
  try {
    const sessionRef = doc(db, 'sessions', sessionId);
    const sessionSnap = await getDoc(sessionRef);
    
    if (!sessionSnap.exists()) {
      return null;
    }

    return {
      id: sessionSnap.id,
      ...sessionSnap.data()
    };
  } catch (error) {
    console.error('Error fetching session:', error);
    return null;
  }
}; 


export const addLead = async (email) => {
  try {
    const docRef = await addDoc(collection(db, 'leads'), {
      email,
      date: serverTimestamp(),
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding lead: ', error);
    throw new Error('Failed to add lead');
  }
};

/**
 * Fetches or creates a session document
 * @param {string|undefined} sessionId - The ID of the session to fetch or create
 * @param {SessionData} [initialData] - Initial data for creating a new session
 * @returns {Promise<SessionData|null>} The session data or null if not found/created
 */
export const getSessionDoc = async (sessionId, initialData = null) => {
  if (!sessionId) return null;
  
  try {
    const sessionDocRef = doc(db, 'sessions', sessionId);
    const sessionDoc = await getDoc(sessionDocRef);
    
    if (sessionDoc.exists()) {
      return {
        id: sessionDoc.id,
        ...sessionDoc.data()
      };
    } else if (initialData) {
      // Create new session with the provided initial data
      await setDoc(sessionDocRef, {
        ...initialData,
        createdAt: serverTimestamp(),
      });
      
      // Fetch and return the newly created session
      const newSessionDoc = await getDoc(sessionDocRef);
      return {
        id: newSessionDoc.id,
        ...newSessionDoc.data()
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching/creating session document:', error);
    return null;
  }
};

export const getCoachDoc = async (coachId) => {
  try {
    const coachDocRef = doc(db, 'coachs', coachId);
    const coachDoc = await getDoc(coachDocRef);
    if (coachDoc.exists()) {
      return coachDoc.data();
    } else {
      console.error('No such coach document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching coach document:', error);
    return null;
  }
};

export const getApiKeyByDomain = async (domain) => {
  try {
    const apiKeyDocRef = doc(db, 'apiKeys', domain);
    const apiKeyDoc = await getDoc(apiKeyDocRef);
    if (apiKeyDoc.exists()) {
      return apiKeyDoc.data().apiKey;
    } else {
      console.error('No API key found for domain:', domain);
      return null;
    }
  } catch (error) {
    console.error('Error fetching API key by domain:', error);
    return null;
  }
};

/**
 * Fetch the API key for the current user's domain.
 */
export const fetchCurrentUserApiKey = async () => {
  try {
    const user = auth.currentUser;
    if (user && user.email) {
      const domain = user.email.split('@')[1];
      const apiKeyDoc = await getDoc(doc(db, 'apiKeys', domain));
      if (apiKeyDoc.exists()) {
        return apiKeyDoc.data().apiKey || null;
      }
    }
    return null;
  } catch (error) {
    console.error('Error fetching API key:', error);
    return null;
  }
};

export const saveCompanyData = async (domainName, data) => {
  try {
    const companyRef = doc(firestore, 'accounts', domainName);
    
    // Saving data to Firestore with provided domainName and data
    await setDoc(companyRef, data, { merge: true });

    console.log(`Company data for ${domainName} saved successfully.`);
  } catch (error) {
    console.error('Error saving company data:', error);
    throw new Error('Failed to save company data. Please try again later.');
  }
};

export const checkCompanyData = async (domainName) => {
  // Attempt to fetch company data for the email domain
  const companyRef = doc(firestore, 'accounts', domainName);
  const companySnapshot = await getDoc(companyRef);

  if (companySnapshot.exists()) {

    let company_data = companySnapshot.data();
    return {
      companyExists: !!company_data.companyName,
      companyName: company_data.companyName,
      companyData: company_data
    };
  } else {
    return { companyExists: false };
  }
};

// Add this function to firebase.utils.ts
export const subscribeToSessionWithMessages = (
  sessionId: string,
  onData: (data: any) => void,
  onError: (error: Error) => void
) => {
  const sessionRef = doc(db, 'sessions', sessionId);
  
  return onSnapshot(
    sessionRef,
    (doc) => {
      if (doc.exists()) {
        const data = {
          id: doc.id,
          ...doc.data(),
          startedAt: doc.data().startedAt?.toDate(),
          finishedAt: doc.data().finishedAt?.toDate(),
        };
        onData(data);
      } else {
        onError(new Error('Session not found'));
      }
    },
    (error) => {
      onError(error);
    }
  );
};

export const checkApiKey = async (domainName) => {
  // Attempt to fetch API key data for the email domain
  const apiKeyRef = doc(firestore, 'apiKeys', domainName);
  const apiKeySnapshot = await getDoc(apiKeyRef);

  if (apiKeySnapshot.exists()) {
    return {
      apiKeyExists: true,
      apiKey: apiKeySnapshot.data().apiKey,
    };
  } else {
    return { apiKeyExists: false };
  }
};


/**
 * Fetch simulations for the current authenticated user.
 */
export const fetchUserSimulations = async () => {
  try {
    const userUid = auth.currentUser?.uid;
    if (userUid) {
      const simulationsRef = collection(db, `accounts/${userUid}/simulations`);
      const simulationSnap = await getDocs(simulationsRef);
      return simulationSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching simulations:', error);
    return [];
  }
};

export const saveMessageToSession = async (sessionId, message) => {
  try {
    const sessionRef = doc(db, 'sessions', sessionId);
    const messagesRef = collection(sessionRef, 'messages');
    
    await addDoc(messagesRef, {
      ...message,
      timestamp: serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error saving message:', error);
    return false;
  }
};

export const getCoaches = async (language) => {
  try {
    const coachesRef = collection(db, 'coachs');
    console.log('get coaches', language);
    let querySnapshot;
    
    if (language) {
      const q = query(coachesRef, where('language', '==', language));
      querySnapshot = await getDocs(q);
    } else {
      querySnapshot = await getDocs(coachesRef);
    }
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching coaches:', error);
    throw error;
  }
};