/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { useEffect, useRef, useCallback, useState, ChangeEvent } from 'react';


import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions as defaultInstructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  VStack,
  HStack,
  Switch,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaTimes, FaBolt, FaPhone } from 'react-icons/fa';

import { User } from 'firebase/auth';


import { useParams, useSearchParams, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for URL params
import { getSessionDoc, getCoachDoc, getApiKeyByDomain, saveMessageToSession, updateSessionStatus } from '../utils/firebase.utils'; 

/**
 * Type for result from get_weather() function call
 */
interface Coordinates {
  lat: number;
  lng: number;
  location?: string;
  temperature?: {
    value: number;
    units: string;
  };
  wind_speed?: {
    value: number;
    units: string;
  };
}

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface ConsolePageProps {
  user: User | null;
}

interface CoachData {
  instructions?: string;
  voice?: 'echo' | 'alloy' | 'shimmer';
  transcriptionModel?: 'whisper-1';
  assistantDescription?: string;
  assistantName?: string;
  callDescription?: string;
  rolePlayingTitle?: string;
  coldCallingDescription?: string;
  language?: string;
}

interface SessionData {
  id?: string;
  coachId?: string;
  status?: string;
  createdAt?: Date;
  apiKey?: string;
  [key: string]: any; // For any additional fields
}

export function ConsolePage({ user }: ConsolePageProps) {

  
  /**
   * Ask user for API Key
   * If we're using the local relay server, we don't need this
   */
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const coachID = searchParams.get('coachId');

  const [apiKey, setApiKey] = useState<string | null>(null);
  const clientRef = useRef<RealtimeClient | null>(null);

  const [instructions, setInstructions] = useState(defaultInstructions);
  const [voice, setVoice] = useState('echo');
  const [transcriptionModel, setTranscriptionModel] = useState('whisper-1');
  const [coachData, setCoachData] = useState<CoachData | null>(null);

  const navigate = useNavigate();

  const [sessionData, setSessionData] = useState<SessionData | null>(null);

  useEffect(() => {
    if (apiKey) {
      // Initialize client when API key is available
      clientRef.current = new RealtimeClient(
        LOCAL_RELAY_SERVER_URL
          ? { url: LOCAL_RELAY_SERVER_URL }
          : {
              apiKey: apiKey,
              dangerouslyAllowAPIKeyInBrowser: true,
            }
      );

      // Set up client configuration immediately after initialization
      const client = clientRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      // Set instructions and transcription
      console.log('updating instructions', instructions);
      client.updateSession({ instructions: instructions, voice: voice as 'echo' | 'alloy' | 'shimmer' | undefined });
      client.updateSession({ input_audio_transcription: { model: transcriptionModel as 'whisper-1' } });

      // handle realtime events from client + server for event logging
      client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
        setRealtimeEvents((realtimeEvents) => {
          const lastEvent = realtimeEvents[realtimeEvents.length - 1];
          if (lastEvent?.event.type === realtimeEvent.event.type) {
            // if we receive multiple events in a row, aggregate them for display purposes
            lastEvent.count = (lastEvent.count || 0) + 1;
            return realtimeEvents.slice(0, -1).concat(lastEvent);
          } else {
            return realtimeEvents.concat(realtimeEvent);
          }
        });
      });
      client.on('error', (event: any) => console.error(event));
      client.on('conversation.interrupted', async () => {
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset;
          await client.cancelResponse(trackId, offset);
        }
      });
      client.on('conversation.updated', async ({ item, delta }: any) => {
        const items = client.conversation.getItems();
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === 'completed' && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
        
        // Save message to Firestore
        if (item.status === 'completed' && id) {
          const messageData = {
            role: item.role,
            type: item.type,
            content: {
              text: item.formatted.text || item.formatted.transcript,
              audio: item.formatted.audio ? true : false,
              tool: item.formatted.tool || null
            },
            itemId: item.id,
            status: item.status
          };
          if( item.formatted.text || item.formatted.transcript){
            await saveMessageToSession(id, messageData);
          }
        }
        
        setItems(items);
      });

      setItems(client.conversation.getItems());

      return () => {
        // cleanup
        client.reset();
      };
    }
  }, [apiKey, instructions, voice, transcriptionModel]);

  useEffect(() => {
    const fetchSessionAndCoachData = async () => {
      if (!id) return; // Early return if id is undefined
      
      let sessionData: SessionData | null = await getSessionDoc(id);
      
      // Create new session if it doesn't exist
      if (!sessionData && coachID) {
        const initialData: SessionData = {
          coachId: coachID,
          status: 'active',
          createdAt: new Date()
        };
        if (id) { // Additional type guard
          sessionData = await getSessionDoc(id, initialData);
        }
      }

      console.log('coachId', coachID);
      const CoachData = await getCoachDoc(coachID);

      if (sessionData && sessionData.apiKey) {
        setApiKey(sessionData.apiKey);
      } else if (user) {
        const email = user.email;
        if (email) {
          const domain = email.split('@')[1];
          const domainApiKey = await getApiKeyByDomain(domain);
          if (domainApiKey) {
            setApiKey(domainApiKey);
            localStorage.setItem('tmp::voice_api_key', domainApiKey);
          } else {
            const storedApiKey = localStorage.getItem('tmp::voice_api_key');
            if (storedApiKey) {
              setApiKey(storedApiKey);
            } else {
              const apiKeyPrompt = prompt('OpenAI API Key');
              if (apiKeyPrompt) {
                setApiKey(apiKeyPrompt);
                localStorage.setItem('tmp::voice_api_key', apiKeyPrompt);
              }
            }
          }
        }
      }

      // Update client configuration with coach data if available
      if (CoachData) {
        console.log('CoachData', CoachData);
        setInstructions(CoachData.instructions || defaultInstructions);
        setVoice(CoachData.voice || 'echo');
        setTranscriptionModel(CoachData.transcriptionModel || 'whisper-1');
        setCoachData(CoachData);
      }
    };

    fetchSessionAndCoachData();
  }, [id, coachID, user]);





  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [isAutoTalk, setIsAutoTalk] = useState(false);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsAutoTalk(newValue);
    changeTurnEndType(newValue ? 'server_vad' : 'none');
  };

  const [coords, setCoords] = useState<Coordinates | null>({
    lat: 37.775593,
    lng: -122.418137,
  });
  const [marker, setMarker] = useState<Coordinates | null>(null);

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    if (!client) return;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);

    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    if(client)
    await client.connect();
  
    if(client)
      if(coachData?.language === 'es'){
        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Ahora va a empezar el juego de roles, debes contestar como que recién llaman a tu telefono y debes contestar en el mismo idioma que están las instrucciones. Debes siempre responder en español.`,
          },
        ]);
      }
      else{
        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Now the role-playing game will start, you must answer as if you just received a phone call and you must answer in the same language as the instructions. You must always answer in English.`,
          },
        ]);
      }

    if (client && client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    const client = clientRef.current;
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});
    setCoords({
      lat: 37.775593,
      lng: -122.418137,
    });
    setMarker(null);

    if (client) {
      client.disconnect();
    }

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();

    // Update session status in Firestore and navigate to score page
    if (id) {
      console.log('disconecting conversation', id);
      try {
        await updateSessionStatus(id, 'finished');
        navigate(`/roleplay/${id}/score`);
      } catch (error) {
        console.error('Error updating session:', error);
      }
    }
  }, [id, navigate]);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    if(!client) return;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    const client = clientRef.current;
    if(!client) return;
    setIsRecording(true);
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    const client = clientRef.current;
    if(!client) return;
    setIsRecording(false);
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    if(!client) return;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Render the application
   */
  return (
    <Box data-component="ConsolePage" p={4}>
      {!user && !apiKey ? (
        <><Text>Invalid Session</Text></>
      ) : (
        <Flex>
          {/* Left Column for Assistant Info */}
          <VStack
            w="80%"
            minW="400px"
            maxW="800px"
            minH="400px"
            maxH="500px"
            p={4}
            spacing={4}
            borderWidth={1}
            borderRadius="md"
            boxShadow="md"
            bg={'gray.50'}
            alignItems="center"
            justifyContent="center"
          >
            {isConnected ? (
              <>
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                  { coachData?.assistantName  || "Assistant Name" }
                </Text>
                <Image
                  src="/profile2.png"
                  alt="Sales Coach"
                  borderRadius="md"
                  boxSize="150px"
                  objectFit="cover"
                />
                <Text textAlign="center">{coachData?.assistantDescription || "Description of the assistant."}</Text>
                <Text textAlign="center">{coachData?.callDescription || "Call Description"}</Text>
                <Button
                  colorScheme="red"
                  onClick={disconnectConversation}
                  leftIcon={<FaTimes />}
                >
                  End Call
                </Button>
              </>
            ) : (
              <>
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                  {coachData?.rolePlayingTitle || "Role Playing Call"}
                </Text>
                <Text textAlign="center">
                  {coachData?.coldCallingDescription || "Description of the cold calling practice."}
                </Text>
                <Image
                  src="/profile2.png"
                  alt="Sales Coach"
                  borderRadius="md"
                  boxSize="150px"
                  objectFit="cover"
                />
                <Button
                  colorScheme="green"
                  onClick={connectConversation}
                  leftIcon={<FaPhone />}
                >
                  Start Call
                </Button>
              </>
            )}
          </VStack>

          {/* Right Column for Conversation and Actions */}
          {isConnected && (
            <VStack
              w="75%"
              minW="500px"
              maxW="800px"
              minH="400px"
              maxH="600px"
              spacing={4}
              pl={4}
              alignItems="stretch"
            >
              <Box
                flex="1"
                overflowY="auto"
                minH="400px"
                maxH="600px"
                minW="700px"
                borderWidth={1}
                borderRadius="md"
                boxShadow="md"
                p={4}
              >
                <Text fontSize="lg" mb={4}>
                  Conversation
                </Text>
                {!items.length && <Text>Awaiting connection...</Text>}
                {items.map((conversationItem, i) => (
                  <Flex
                    key={conversationItem.id}
                    justify="space-between"
                    align="center"
                    mb={2}
                  >
                    <Box>
                      <Text fontWeight="bold">
                        {(
                          conversationItem.role || conversationItem.type
                        ).replaceAll('_', ' ')}
                      </Text>
                      <Box>
                        {conversationItem.type === 'function_call_output' && (
                          <Text>{conversationItem.formatted.output}</Text>
                        )}
                        {!!conversationItem.formatted.tool && (
                          <Text>
                            {conversationItem.formatted.tool.name}(
                            {conversationItem.formatted.tool.arguments})
                          </Text>
                        )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'user' && (
                            <Text>
                              {conversationItem.formatted.transcript ||
                                (conversationItem.formatted.audio?.length
                                  ? '(awaiting transcript)'
                                  : conversationItem.formatted.text ||
                                    '(item sent)')}
                            </Text>
                          )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'assistant' && (
                            <Text>
                              {conversationItem.formatted.transcript ||
                                conversationItem.formatted.text ||
                                '(truncated)'}
                            </Text>
                          )}
                        {conversationItem.formatted.file && (
                          <audio
                            src={conversationItem.formatted.file.url}
                            controls
                          />
                        )}
                      </Box>
                    </Box>
                    <IconButton
                      icon={<FaTimes />}
                      onClick={() =>
                        deleteConversationItem(conversationItem.id)
                      }
                      aria-label="Delete conversation item"
                    />
                  </Flex>
                ))}
              </Box>

              {/* Footer Actions */}
              <HStack w="100%" justify="space-between" p={4}>
                <Switch
                  size="lg"
                  isChecked={isAutoTalk}
                  onChange={handleSwitchChange}
                >
                  Auto/Manual
                </Switch>
                {isConnected && canPushToTalk && (
                  <Button
                    colorScheme={isRecording ? 'red' : 'blue'}
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                  >
                    {isRecording ? 'Release to send' : 'Push to talk'}
                  </Button>
                )}
              </HStack>
            </VStack>
          )}
        </Flex>
      )}
    </Box>
  );
}
