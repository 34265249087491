import React, { useEffect, useState } from 'react';
import { getCoaches } from '../utils/firebase.utils';
import {
  Grid,
  Box,
  Image,
  Text,
  Button,
  Heading,
  VStack,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';

interface Coach {
  id: string;
  assistantName: string;
  assistantDescription: string;
  language: string;
  imageUrl?: string;
}

interface CoachesListProps {
  language?: string;
}

const CoachesList: React.FC<CoachesListProps> = ({ language }) => {
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const coachesData = await getCoaches(language);
        setCoaches(coachesData as Coach[]);
      } catch (error) {
        console.error('Error fetching coaches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoaches();
  }, [language]);

  const handleStartRoleplay = (coachId: string) => {
    const sessionId = crypto.randomUUID();
    window.location.href = `/roleplay/${sessionId}?coachId=${coachId}`;
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
      gap={6}
      p={6}
    >
      {loading ? (
        <>
          {[1, 2, 3].map((n) => (
            <Box
              key={n}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              borderColor={borderColor}
              bg={bgColor}
            >
              <Skeleton height="200px" />
              <VStack p={6} spacing={3} align="stretch">
                <Skeleton height="24px" width="70%" />
                <Skeleton height="16px" />
                <Skeleton height="16px" width="80%" />
                <Skeleton height="40px" />
              </VStack>
            </Box>
          ))}
        </>
      ) : (
        coaches.map(coach => (
          <Box
            key={coach.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            borderColor={borderColor}
            bg={bgColor}
            _hover={{ shadow: 'lg' }}
            transition="all 0.2s"
          >
            <Image
              src={coach.imageUrl || '/default-coach.png'}
              alt={coach.assistantName}
              height="200px"
              width="100%"
              objectFit="cover"
              fallback={<Skeleton height="200px" />}
            />
            <VStack p={6} spacing={3} align="stretch">
              <Heading size="md" noOfLines={2}>
                {coach.assistantName}
              </Heading>
              <Text color="gray.600" noOfLines={3}>
                {coach.assistantDescription}
              </Text>
              <Button
                colorScheme="blue"
                size="lg"
                onClick={() => handleStartRoleplay(coach.id)}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                transition="all 0.2s"
              >
                Start Roleplay Session
              </Button>
            </VStack>
          </Box>
        ))
      )}
    </Grid>
  );
};

export default CoachesList; 